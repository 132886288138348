<template>
  <svg
    class="s-icon"
    :width="size"
    :height="size"
    :color="color"
    v-on="$listeners"
  >
    <use :xlink:href="require('@/assets/svgs/icons.svg') + `#${name}`" />
  </svg>
</template>

<script>
export default {
  name: 'SIcon',
  props: {
    size: {
      type: String,
      default: '24px',
    },
    color: {
      type: String,
      default: '#737A91',
    },
    name: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.s-icon {
  cursor: pointer;
}
</style>
